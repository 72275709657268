.image-wrapper {
  width: 100%;
  height: 100%;
  margin-bottom: 80px;
  transform: scaleY(1);
  position: relative;
  &__img-birds {
    position: absolute;
    width: 50vw;
    z-index: 100;
    transform: translateX(-10vw) translateY(-10vw) scale(1);
  }
}



.image-container {
  width: 84vw;
  height: 127vw;
  overflow: hidden;
  position: relative;
  border-radius: 30px;
  background: white;
  transform-origin: top;
  z-index: 1;
  &__img {
    position: absolute;
    width: 84vw;
    height: 127vw;
    transform: scale(1);
    animation: both;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1025px) {
  .image-container {
    width: 80vw;
    height: 121vw;
    &__img {
      width: 84vw;
      height: 127vw;
    }
  }
}

@media only screen and (min-width: 1025px) {
  .image-wrapper {
    grid-row: 2;
    width: 100%;
    height: 100%;
    margin-bottom: 80px;
    transform: scaleY(1);
    &__img-birds {
      position: absolute;
      width: 25vw;
      z-index: 100;
      transform: translateX(-4vw) translateY(-4vw) scale(1);
    }
  }
  .image-container {
    width: 35vw;
    height: 53vw;
    overflow: hidden;
    position: relative;
    border-radius: 30px;
    background: white;
    z-index: 1;
    &__img {
      width: 35vw;
      height: 53vw;
      transform: scale(1);
    }
  }
}

.donate-landing {
  display: grid;
  grid-template-rows: 30vh min-content auto;
  &__heading {
    align-self: center;
  }
  &__img {
    width: 100%;
    margin-bottom: 60px;
  }
}

.data-container {
  display: grid;
  grid-template-columns: auto 1fr;
  margin: 5px 0 30px 0;
  column-gap: 20px;
  // row-gap: 10px;
  width: 100%;
  &.right {
    justify-self: right;
  }
  & .wide{
    grid-column: 1/-1;
    margin: 40px 0 20px;
    opacity: .8;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1025px) {
  .donate-landing {
    display: grid;
    grid-template-rows: 30vh min-content auto;
    &__img {
      width: 70%;
      margin-bottom: 60px;
    }
  }

  .data-container {
    column-gap: 40px;
  }
}

@media only screen and (min-width: 1025px) {
  .donate-landing {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    height: 60vh;
    align-items: center;
  }

  .data-container {
    column-gap: 60px;
  }
}

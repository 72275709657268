.page-wrapper {
    padding-top: 100px;
    width: 84vw;
    margin: auto;
}
.page{
    padding-bottom: 40px;
}

@media only screen and (min-width: 600px) and (max-width: 1025px) {
    .page-wrapper {
        padding-top: 140px;
        width: 80vw;
    }
    .page{
        padding-bottom: 60px;
    }
}

@media only screen and (min-width: 1025px) {
    .page-wrapper {
        padding-top: 180px;
        width: 80vw;
    }
    .page{
        padding-bottom: 80px;
    }
}

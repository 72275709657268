// Palette
$light: #fff;
$light-blue: #e2f0f9;
$blue: #286fb4;
$sea-blue: #b0dde4;
$pink: #f472a9;
$dark: #262626;

@keyframes fadeIn {
  0% {
    opacity: 0;
    bottom: 0;
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
    opacity: 1;
  }
}

@keyframes hide {
  0% {
  }
  100% {
    height: 40px;
  }
}

// Menu Top Bar
.navbar {
  position: fixed;
  height: 80px;
  display: grid;
  width: 100vw;
  padding: 0 8vw 0 8vw;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  z-index: 100;
  background: #fff;

  &__logo {
    position: relative;
    width: 40vw;
    max-width: 150px;
    cursor: pointer;
    &--index {
      z-index: 1000;
    }
  }
  &__menu {
    z-index: 200;
  }
  &__hamburger {
    z-index: 200;
    grid-column: 3;
    justify-self: right;
  }
}

// Menu Mobile
.menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: $sea-blue;

  display: grid;
  grid-template-rows: 1fr min-content 100px;

  transform: translateX(125vw) skew(6deg);
  transition: 0.4s ease;
  &__links-container {
    grid-row: 2;
    margin: 0 0 4rem 8vw;
    display: flex;
    flex-direction: column;
    min-width: 0;
  }
  &__link {
    max-width: min-content;
    color: $light;
    display: inline !important;
    font-size: 3.5rem !important;
    margin: 2rem 0;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent !important;
    transition: 0.4s ease;
    position: relative;

    &--secondary {
      display: inline !important;
      font-size: 1.6rem !important;
      font-weight: 300 !important;
      text-transform: uppercase;
    }
  }
  &__links-container-secondary {
    margin: 0 8vw;
    width: 84vw;
    grid-row: 3;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__translate {
    width: 16pt;
    & path {
      fill: $light;
    }
  }
  &--open {
    background: $blue;
    transform: translateX(0) rotate(0deg);
    transition: 0.6s ease;
  }
}

// SVG light mode
.light g {
  fill: $light;
  transition: 0.4s ease 0.1s;
}

.active {
  position: relative;
  opacity: 1;
  &::after {
    content: url(./heart.svg);
    display: flex;
    justify-content: center;
    align-content: center;
    position: absolute;
    height: 100%;
    zoom: 130%;
    top: -8px;
    right: -40px;
    animation: fadeIn 0.3s ease-in-out both;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1025px) {
  .navbar {
    padding: 0 10vw 0 10vw;
    height: 100px;
  }
}

@media only screen and (min-width: 1025px) {
  // Menu Top Bar
  .navbar {
    height: 120px;
    padding: 0 10vw 0 10vw;
    &__logo {
      max-width: 175px;
    }
    &__hamburger {
      display: none;
    }
  }

  // Menu Desktop
  .menu {
    all: unset;
    grid-column: 3;
    justify-self: end;
    &__links-container {
      all: unset;
      display: flex;
      justify-content: space-between;
      width: 40vw;
    }
    &__link {
      all: unset;
      color: $dark;
      font-size: 2rem !important;
      font-weight: 500;
      cursor: pointer;
      opacity: 0.5;
      transition: 0.3s ease-in-out;
      &:hover {
        opacity: 1;
        transform: scale(1.05);
      }
      &--secondary {
        opacity: 1;
        font-weight: 300 !important;
        font-size: 1.2rem !important;
        text-transform: uppercase;
        color: #959595;
      }
    }
    &__links-container-secondary {
      all: unset;
      position: fixed;
      top: 0;
      right: 10vw;
      width: 10vw;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__translate {
      all: unset;
      width: 16pt;
      & path {
        fill: #959595;
      }
    }
    &--open {
      background: unset;
      transform: unset;
      transition: unset;
    }
  }

  // SVG light mode
  .light g {
    fill: unset;
    transition: unset;
  }

  .noActive.active {
    &::after {
      content: "";
      height: 0;
    }
  }

  .active {
    position: relative;
    opacity: 1;

    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      height: 5px;
      width: 100%;
      left: 0;
      top: unset;
      bottom: -10px;
      background: $sea-blue;
      animation: fadeIn 0.3s ease-in-out both;
    }
  }
}

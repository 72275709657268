.homepage{

}
.homepage-landing {
  min-height: 100vh;
  &__heading {
    margin: 40px 0 40px 0;
  }
  &__content {
    display: none;
  }
}

.homepage-quote {
  position: relative;
  display: grid;
  min-height: 80vh;
  justify-content: center;
  align-content: center;
  &__text {
    margin:auto;
    width: 90%;
    line-height: 2;
    text-align: center;
  }
  &__img{
    position: absolute;
    width: 40vw;
    &--top{
      top:-50px;
      right:5vw;;
      transform: rotate(30deg);
    }
    &--bottom{
      bottom:-30px;
      left:-5vw;
      transform: rotateY(180deg);
    }
  }
}

.homepage-about{
&__hands{
  width: 130vw;
  transform: translateX(15vw);
}
}

@media only screen and (min-width: 600px) and (max-width: 1025px) {
  .homepage-quote {
    &__text {
      margin:auto;
      width: 90%;
      line-height: 2;
      text-align: center;
    }
    &__img{
      position: absolute;
      width: 30vw;
      &--top{
        top:-50px;
        right:5vw;;
        transform: rotate(30deg);
      }
      &--bottom{
        bottom:-30px;
        left:-5vw;
        transform: rotateY(180deg);
      }
    }
  }
  .homepage-about{
    &__text{
      width:70% ;
    }
    &__hands{
      width: 100vw;
      transform: translateX(25vw);
    }
  }
}

@media only screen and (min-width: 1025px) {

  .homepage__img-container{
    grid-row: 1;
    grid-column: 1;
  }

  .homepage-landing {
    display: grid;
    grid-template-columns: auto 1fr;
    &__heading {
      grid-row: 2;
      grid-column: 2;
      margin: 150px 0 110px 5vw;
      align-self: start;
    }
    &__content {
      display: unset;

      margin-top: 60px;
    }
  }



  .homepage-quote {
    min-height: 70vh;
    &__text {
      width: 60%;
    }
    &__img{
      position: absolute;
      width: 15vw;
      &--top{
        top:-15vw;
        right:-5vw;;
        transform: rotate(30deg);
      }
      &--bottom{
        bottom:-30px;
        left:-9vw;
        transform: rotateY(180deg);
      }
    }
  }

  .homepage-about{
    width: 100%;
    position: relative;
    &__text{
      width: 60%;
    }
    &__hands{
      position: absolute;
      right: -25vw;
      top:0;
      width: 60vw;
      transform: translateX(0);
    }
    }

}


// Palette
$light: #fff;
$light-blue: #e2f0f9;
$blue: #286fb4;
$sea-blue: #b0dde4;
$pink: #f472a9;
$dark: #262626;

.form-container{
  margin: 60px 0 120px 0;
}

.radioInput-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.form-amount-btn {
  all: unset;
  cursor: pointer;
  display: grid;
  justify-items: center;
  align-items: center;
  border-radius: 30px;
  border: 3px solid $light-blue;
  padding: 0 10px;
  height: 30px;
  width: min-content;
  text-align: center;
  margin: 5px 5px;
  transition: 0.4s ease-in-out;
  font-size: 1.2rem;
  font-weight: 500;
  &:focus {
    background: $pink;
    font-weight: 700;
    font-size: 1.2rem;
    border: 3px solid $pink;
    width: min-content;
    color: white;
    &::placeholder{
      color: white;
    }
  }
  &:hover {
    border: 3px solid $pink;
  }
  &::placeholder {
    color: $dark;
    font-size: 1.2rem;
    font-weight: 500;
    margin-left: 5px;
    position: relative;
  }
  &[attribute="value"] {
    position: relative;
    &::after {
      content: "PLN";
      position: absolute;
      right: 0;
    }
  }
}
.set {
  background: $pink;
  border-color: $pink;
  color: $light;
  height: 30px;
  & p {
    color: white;
    font-weight: bold;
  }
}

.form-text-container {
  width: 100%;
  height: 40px;
  border-radius: 40px;
  background: $light-blue;
  display: grid;
  grid-template-columns: 40% 1fr;
  align-items: center;
  margin: 20px 0;
  & p,
  &::placeholder {
    transform: translateX(10px);
  }
  & input {
    all: unset;
    height: 30px;
    border-radius: 30px;
    background: $light;
    justify-self: end;
    width: 100%;
    margin-right: 5px;
    padding-left: 10px;
  }
}

.form-checkbox {
  display: grid;
  grid-template-columns: 10% 1fr;
  width: 100%;
  align-items: center;
  justify-items: center;
  margin: 10px 0;
  & input[type="checkbox"]{
    cursor: pointer;
  }
}

// Setting fonts for Name/Email forms
input[type="text"],
input[type="email"] {
  cursor: text;
  color: $dark;
  font-size: 1.2rem;
  font-weight: 500;
  &::placeholder{
    opacity: .4;
  }
}

.submit-button {
  color: $light;
  padding: 0 15px;
  height: 40px;
  background: $pink;
  border-radius: 40px;
  margin: 15px 0;
  float: right;
  cursor: pointer;
  transition: .4s ease-in-out;
  &:hover{
    transform: scale(1.1);
  }
}

// Fixing autofill color change..
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

@media only screen and (min-width: 600px) and (max-width: 1025px) {
  .form-container{
    margin: 60px 0 120px 0;
    width: 60%;
  }
}

@media only screen and (min-width: 1025px) {
  .form-container{
    margin: 60px 0 180px 0;
    width: 60%;
  }
}

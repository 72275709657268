.center-header {
  text-align: center;
  margin: auto;
  justify-content: center;
  width: 100%;
}

.header-wrapper {
  display: inline-block;
  margin: 40px 0 40px 0;
  &__h1 {
    margin: 0 0 40px 0;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1025px) {
  .header-wrapper {
    margin: 50px 0 50px 0;
    &__h1 {
      margin: 0 0 50px 0;
    }
  }
}

@media only screen and (min-width: 1025px) {
  .center-header {
    text-align: left;
  }

  .header-wrapper {
    margin: 60px 0 60px 0;
    &__h1 {
      margin: 0 0 60px 0;
    }
  }
}

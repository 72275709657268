// Palette
$light: #fff;
$light-blue: #e2f0f9;
$blue: #286fb4;
$sea-blue: #b0dde4;
$pink: #f472a9;
$dark: #262626;

.footer {
  display: inline-block;
  width: 100%;
  background: $blue;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  &__logo {
    display: none;
  }
  &__section {
    margin: 8vw;
    & .h2 {
      margin-bottom: 2vw;
    }
    & .h4 {
      opacity: 0.8;
    }
  }
  &__links {
    display: flex;
    margin: 2vw 8vw;
    justify-content: space-between;
    & .h4 {
      opacity: 0.6;
      text-transform: uppercase;
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 1025px) {
  .footer {
    display: grid;
    grid-template-columns: auto auto 1fr;
    &__logo {
      display: initial;
      align-self: center;
      width: 10vw;
      margin: 2vw 5vw;
      grid-column: 3;
      grid-row: 1/2;
      justify-self: right;
    }
    &__section {
      grid-row: 1;
      margin: 5vw 0 3vw 5vw;
    }
    &__links {
      grid-row: 2;
      grid-column: 1/-1;
      justify-self: end;
      margin: 10px 5vw 10px 0;
      justify-content: right;
      & .h4 {
        font-size: 1.2rem;
        margin-right: unset;
        margin-left: 60px;
      }
    }
  }
}

@media only screen and (min-width: 1025px) {
  .footer {
    display: grid;
    grid-template-columns: auto auto 1fr;
    &__logo {
      display: initial;
      align-self: center;
      width: 10vw;
      margin: 2vw 5vw;
      grid-column: 3;
      grid-row: 1/2;
      justify-self: right;
    }
    &__section {
      grid-row: 1;
      margin: 5vw 0 3vw 5vw;
    }
    &__links {
      grid-row: 2;
      grid-column: 1/-1;
      justify-self: end;
      margin: 10px 5vw 10px 0;
      justify-content: right;
      & .h4 {
        font-size: 1.2rem;
        margin-right: unset;
        margin-left: 60px;
      }
    }
  }
}

// Palette
$light: #fff;
$light-blue: #e2f0f9;
$blue: #286fb4;
$sea-blue: #b0dde4;
$pink: #f472a9;
$dark: #262626;

.Accordicon {
  &__wrapper {
    transform-origin: top;
    position: relative;
    overflow: hidden;
    height: 0;
  }
  &__button {
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 10px;
    transition: .4s ease-in-out;
    -webkit-tap-highlight-color: transparent !important;
    &:hover{
      color: $pink;
    }
  }
}


// Palette
$light:#fff;
$light-blue:#E2F0F9;
$blue:#286FB4;
$sea-blue:#B0DDE4;
$pink:#F472A9;
$dark:#262626;

video{
  max-width: 100%;
  min-width: 100%;min-height: 100%;
  border-radius: 10px;
  outline: none;
  transition: .3s ease-in-out;
  &:hover{
    transform: scale(1.03);
  }
  &::-webkit-media-controls-panel{
    background-image: linear-gradient(transparent,transparent) !important;
    background-size: auto min-content ;
  }
}

.movies{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1,1fr);
  row-gap: 30px;
  justify-items: center;
  align-items: center;
  column-gap: 30px;
  padding: 0 0 50px 0;
}

.proteges {
  position: relative;
  &__header {
    margin-top: 80px;
  }
  &__birds {
    position: absolute;
    right: 2vw;
    top: -30px;
    transform: rotate(45deg);
    width: 30vw;
    // animation: expand 1.4s ease-in-out both;
  }
}
.img{
  border-radius: 15px;
}

.image-grid-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5vw;
  margin-bottom: 60px;

  &__column {
    display: grid;
    row-gap: 5vw;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1025px) {

  .movies{
    grid-template-columns: repeat(3,1fr);
  }
  .img{
    border-radius: 20px;
  }
  .proteges {
    &__header {
      margin-top: 100px;
    }
    &__birds {
      transform: rotate(65deg);
      right: 2vw;
      top: -80px;
      width: 25vw;
    }
  }
}

@media only screen and (min-width: 1025px) {
  .movies{
    grid-template-columns: repeat(3,1fr);
  }
  .img{
    border-radius: 30px;
  }
  .proteges {
    &__header {
      margin-top: 100px;
      width: 70%;
    }
    &__birds {
      transform: rotate(65deg);
      right: 2vw;
      top: -80px;
      width: 25vw;
    }
  }
}

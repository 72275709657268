.foundation {
    width: 100%;
    display: grid;
    justify-content: left;
    &__img{
      margin: 40px auto 40px auto;
      width: 60%;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1025px) {

  .foundation {
    &__landing{
      position: relative;
      width: 60%;
    }
    &__image-container{
      position: absolute;
      width: 100%;
      height: 100%;
      display: grid;
      top:0;
      right: -32vw;
      pointer-events: none;
    }
    &__img{
      width: 25vw;
      margin: 0;
      justify-self: right;
      align-self: center;
    }
}

}

@media only screen and (min-width: 1025px) {
  .spacer{
    width: 20px;
    height: 10px;
    display: inline-block;
  }
  .foundation {
    &__landing{
      position: relative;
      width: 60%;
    }
    &__image-container{
      position: absolute;
      width: 100%;
      height: 100%;
      display: grid;
      top:0;
      right: -32vw;
      pointer-events: none;
    }
    &__img{
      width: 25vw;
      margin: 0;
      justify-self: right;
      align-self: center;
    }
}

.foundation-info{
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 5vw;
  &__block--2{
    align-self: end;
  }
}

}
// Palette
$light: #fff;
$light-blue: #e2f0f9;
$blue: #286fb4;
$sea-blue: #b0dde4;
$pink: #df4c73;
$dark: #262626;

.line{
  width: 100%;
  display: flex;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrapper .line1 {
  width: 100%;
  height: 3.5rem;
  position: relative;
  overflow: hidden;
}
.wrapper .line1 span {
  width: 100%;
  position: absolute;
  color: $dark;
}

@media only screen and (min-width: 600px) and (max-width: 1025px) {
  .wrapper .line1 {
    height: 4.2rem;
  }
}

@media only screen and (min-width: 1025px) {
  .wrapper .line1 {
    height: 6rem;
  }
}

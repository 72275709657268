// Palette
$light:#fff;
$light-blue:#E2F0F9;
$blue:#286FB4;
$sea-blue:#B0DDE4;
$pink:#F472A9;
$dark:#262626;


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Ubuntu', sans-serif;
  font-size: 10px;
  line-height: 1.2;
  color: $dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
}

body {
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  overscroll-behavior-y: none ;

}
html {
  overflow: hidden;
  overflow-y: scroll;
  overscroll-behavior-y: none ;
}

img{
  display: block;
  width: 100%;
}

button{
  all: unset;
}



// Fonts

//Font Sizes Mobile
.h1 {
  font-size: 2.5rem;
}
.h2 {
  font-size: 2rem;
}
.h3 {
  font-size: 1.6rem;
}
.h4 {
  font-size: 1.2rem;
  line-height: 1.8;
}
.h5 {
  font-size: 1rem;
  line-height: 1.4;
}
//Font Weights
.thin{
  font-weight: 300;
}
.regular {
  font-weight: 400;
}
.medium {
  font-weight: 500;
}
.bold {
  font-weight: 700;
}
.uppercase{
  text-transform: uppercase;;
}

// Colors
.accent {
  display: inline;
  color: $pink !important;
  &--blue{
    display: inline;
    color: $blue !important;
  }
}
.light {
  color: $light;
}


@media only screen and (min-width: 600px) and (max-width: 1025px) {

  //Font Sizes Tablet
  .h1 {
    font-size: 3.5rem;
  }
  .h2 {
    font-size: 2.5rem;
  }
  .h3 {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 1025px) {

  //Fonte Sizes Desktop
  .h1 {
    font-size: 5rem;
  }
  .h2 {
    font-size: 3rem;
  }
  .h3 {
    font-size: 2rem;
  }
  .h4 {
    font-size: 1.6rem;
  }
  .h5{
    font-size: 1.2rem;
  }
}

// Palette
$light:#fff;
$light-blue:#E2F0F9;
$blue:#286FB4;
$sea-blue:#B0DDE4;
$pink:#F472A9;
$dark:#262626;

.cta-wrapper {
  position: fixed;
  bottom: 6vw;
  right:calc(8vw - 25px);
  display: flex;
  z-index: 10;
  visibility: hidden;
  transition: .4s ease-in-out;
  cursor: pointer;
  &:hover{
    transform: scale(1.1) translateX(-10px);
  }

}
.cta {
  all: unset;
  pointer-events: all;
  position: relative;
  background: $pink;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.222);



  &__icon {
    position: absolute;
    width: 15px;
    margin-top: 1px;
    pointer-events: none;

  }
  &__text{
    color: $dark;
    text-align: center;
  }
  &__text-container {
    display: flex;
    justify-items: center;
    align-items: center;
    padding: 0 55px 0 30px;
    transform: translateX(25px);
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    height: 50px;
    background: $light;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.103);
    transform-origin: right;
  }
}


@media only screen and (min-width: 600px) and (max-width: 1025px) {

  .cta-wrapper {
    bottom: 6vw;
    right:calc(10vw - 25px);
  }
  .cta {
    width: 50px;
    height: 50px;
  
    &__icon {
      width: 15px;
      margin-top: 1px;
    }
    &__text-container {
      padding: 0 55px 0 30px;
      transform: translateX(25px);
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      height: 50px;
    }
  }
}

@media only screen and (min-width: 1025px) {

  .cta-wrapper {
    right:calc(10vw - 25px);
    &:hover{
      transform: scale(1.1) translateX(-9px);
    }
  
  }

  .cta {
    width: 50px;
    height: 50px;
  
    &__icon {
      width: 15px;
      margin-top: 1px;
    }
    &__text-container {
      padding: 0 55px 0 30px;
      transform: translateX(25px);
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      height: 50px;
    }
  }
  
}


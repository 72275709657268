// Palette
$light:#fff;
$light-blue:#E2F0F9;
$blue:#286FB4;
$sea-blue:#B0DDE4;
$pink:#F472A9;
$dark:#262626;

.splash {
  visibility: hidden;
  position: relative;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: $light;

  display: grid;
  grid-template-rows: 100vh;
  justify-items: center;
  align-items: center;
  z-index: 100;
  &__text{
    position: absolute;
    color:$blue;
    right: 4vw;
    bottom: 4vw;
  }
  &__wrapper {
    justify-self: left;
    margin-left: 8vw;
    grid-row: 1;
    grid-column:1 ;
  }
  &__img-logo {
    width: 30vw;
    grid-row: 1;
    grid-column:1;
    z-index: -10;
  }
  &__frame {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    z-index: -1;
    left: 0vw;
    background: $blue;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrapper .line {
  width: 100%;
  height: 3.5rem;
  position: relative;
  overflow: hidden;
}
.wrapper .line span {
  position: absolute;
  color: $dark;
}

@media only screen and (min-width: 600px) and (max-width: 1025px) {
  .splash {
    &__wrapper {
      margin-left: 10vw;
    }
    &__img-logo {
      width: 30vw;
    }
  }
  .wrapper .line {
    height: 4.2rem;
  }
}

@media only screen and (min-width: 1025px) {
  .splash {
    &__wrapper {
      margin-left: 10vw;
    }
    &__img-logo {
      width: 30vw;
    }
  }

  .wrapper .line {
    height: 6rem;
  }
}
